var map;
var markers = [];
var infoWindows = [];

function initMap(){
    map = new google.maps.Map(document.getElementById('map'), {
        center: {
            lat: 40.624660,
            lng: 141.216809
        },
        zoom: 15,
        zoomControl: false, //ズームコントロールを非表示
        fullscreenControl: false, //全画面表示を非表示
        mapTypeControl: false, //地図・衛星写真の切り替え
        streetViewControl: false, //ストリートビュー コントロール
        scrollwheel: false
    });
    // var marker = new google.maps.Marker({
    //     position: new google.maps.LatLng(40.624660, 141.216809),
    //     map: map,
    //     icon: 'images/pin-365.png'
    // });

    //  ここからグレースケール設定
    // var mapStyleOptions = [{
    //     stylers: [
    //         { saturation: -100 }
    //     ]
    // }];
    var lopanType = new google.maps.StyledMapType(mapStyleOptions);
    map.mapTypes.set('sample', lopanType);
    map.setMapTypeId('sample');
}
